import { lazy as loadable } from '@loadable/component'

import { createBrandedComponent } from '../create-branded-component'

const BRANDED_COMPONENTS = {
  playboom: loadable(() => import('./wallet-menu-item-playboom')),
  playboom24: loadable(() => import('./wallet-menu-item-playboom')),
  freedomcasino: loadable(() => import('./wallet-menu-item-paradiseplay')),
  wettenlive: loadable(() => import('./wallet-menu-item-wettenlive-casinosky')),
  casinosky: loadable(() => import('./wallet-menu-item-wettenlive-casinosky')),
  casinoskyjp: loadable(() =>
    import('./wallet-menu-item-wettenlive-casinosky')
  ),
  paradiseplay: loadable(() => import('./wallet-menu-item-paradiseplay')),
}

export const WalletMenuItem = createBrandedComponent(
  'WalletMenuItem',
  BRANDED_COMPONENTS
)
