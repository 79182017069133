import { lazy as loadable } from '@loadable/component'

import { createBrandedComponent } from '../create-branded-component'

const BRANDED_COMPONENTS = {
  playboom: loadable(() => import('./promotions-menu-item-playboom')),
  playboom24: loadable(() => import('./promotions-menu-item-playboom')),
  freedomcasino: loadable(() => import('./promotions-menu-item-paradiseplay')),
  wettenlive: loadable(() => import('./promotions-menu-item-wettenlive')),
  casinosky: loadable(() => import('./promotions-menu-item-casinosky')),
  casinoskyjp: loadable(() => import('./promotions-menu-item-casinosky')),
  paradiseplay: loadable(() => import('./promotions-menu-item-paradiseplay')),
}

export const PromotionsMenuItem = createBrandedComponent(
  'PromotionsMenuItem',
  BRANDED_COMPONENTS
)
