import { lazy as loadable } from '@loadable/component'

import { createBrandedComponent } from '../create-branded-component'

const BRANDED_COMPONENTS = {
  playboom: loadable(() => import('./menu-item-playboom')),
  playboom24: loadable(() => import('./menu-item-playboom')),
  freedomcasino: loadable(() => import('./menu-item-paradiseplay')),
  wettenlive: loadable(() => import('./menu-item-wettenlive-casinosky')),
  casinosky: loadable(() => import('./menu-item-wettenlive-casinosky')),
  casinoskyjp: loadable(() => import('./menu-item-wettenlive-casinosky')),
  paradiseplay: loadable(() => import('./menu-item-paradiseplay')),
}

export const MenuItem = createBrandedComponent('MenuItem', BRANDED_COMPONENTS)
