import { lazy as loadable } from '@loadable/component'

import { createBrandedComponent } from '../create-branded-component'

const BRANDED_COMPONENTS = {
  playboom: loadable(() => import('./support-chat-menu-item-playboom')),
  playboom24: loadable(() => import('./support-chat-menu-item-playboom')),
  freedomcasino: loadable(() =>
    import('./support-chat-menu-item-paradiseplay')
  ),
  wettenlive: loadable(() =>
    import('./support-chat-menu-item-wettenlive-casinosky')
  ),
  casinosky: loadable(() =>
    import('./support-chat-menu-item-wettenlive-casinosky')
  ),
  casinoskyjp: loadable(() =>
    import('./support-chat-menu-item-wettenlive-casinosky')
  ),
  paradiseplay: loadable(() => import('./support-chat-menu-item-paradiseplay')),
}

export const SupportChatMenuItem = createBrandedComponent(
  'SupportChatMenuItem',
  BRANDED_COMPONENTS
)
